import React from 'react'
import styled from 'styled-components'

import Article from 'components/article'

import media from 'utils/media-queries'

import TwoImageWrapper from 'components/twoImageWrapper'
import ImgLandscape from 'components/imgLandscape'

import UISrc from 'img/articles/lifetime/lifetime-app.jpg'
import UI2Src from 'img/articles/lifetime/lifetime-app2.jpg'
import TasksSrc from 'img/articles/lifetime/lifetime-tasks.jpg'
import OnboardingSrc from 'img/articles/lifetime/lifetime-illustration.svg'
import DesktopSrc from 'img/articles/lifetime/lifetime-desktop.jpg'
import IconsSrc from 'img/articles/lifetime/lifetime-icons.svg'
import LogoSrc from 'img/articles/lifetime/lifetime-logo.svg'
import HeartSpinnerSrc from 'img/articles/lifetime/lifetime-logoanimation.gif'
import { color } from 'styles/theme'
import Seo from 'components/seo'

const ImageGrid = styled.div`
  background: ${color.coolGrey100};
  max-width: 100%;
  padding: 40px;
  text-align: center;
  ${media.lg`
    padding: 32px;
    margin: 0 0 24px 0;
  `}
  ${media.md`
    margin: 0;
    margin: 0 0 16px 0;
  `}
  ${media.sm`
    padding: 16px;
  `}
`

const Img = styled.img`
  max-width: calc(100% - 96px);
  max-height: 300px;
  margin: 8px;
  display: inline-block;
  ${media.xl`
    max-height: 240px;
  `}
  ${media.lg`
    max-height: 200px;
  `}
  ${media.md`
    max-height: 120px;
    margin: 4px;
  `}
  ${media.sm`
    max-height: 80px;
    margin: 2px;
  `}
`

const LifeTime = () => {
  return (
    <Article
      title="LifeTime"
      description="Secure medical messenger"
      from="2014"
      to="2022"
      roles={['Co-Founder', 'Head of Product Design', 'Frontend Developer']}
      text={
        <>
          <p>
            LifeTime is an end-to-end encrypted messaging solution, medication
            and document manager for the German healthcare market.
          </p>
          <p>
            It runs on iOS, Android, Windows, Linux and MacOS and integrates
            with multiple native and web components. This variety of target
            platforms made designing the product an exciting challenge.
          </p>
          <p>
            LifeTime attracted more than 500.000 users (patients & doctors) and
            was acquired by the Akquinet founders in early 2022.
          </p>
        </>
      }
      more={
        <>
          <Seo title="LifeTime" />
          <TwoImageWrapper>
            <ImgLandscape img={TasksSrc} aspectRatio={1.2} />
            <ImgLandscape img={UISrc} />
          </TwoImageWrapper>
          <TwoImageWrapper>
            <ImgLandscape img={UI2Src} aspectRatio={1.1} />
            <ImgLandscape img={DesktopSrc} aspectRatio={1.3} />
          </TwoImageWrapper>
          <ImageGrid>
            <Img src={LogoSrc} />
            <Img src={IconsSrc} />
            <Img src={OnboardingSrc} />
            <Img src={HeartSpinnerSrc} />
          </ImageGrid>
        </>
      }
    />
  )
}

export default LifeTime
