import React from 'react'
import styled from 'styled-components'

import GlobalWrapper from 'components/global-wrapper'
import media from 'utils/media-queries'

import Header from 'components/header'
import Footer from 'components/footer'

import { color, fontSize } from 'styles/theme'
import { v } from 'utils/v-size'

const Section = styled.section`
  min-height: calc(100vh - 194px);
  padding-bottom: 2rem;
`

const Container = styled.div`
  padding-top: calc(32px + 4vw);
  padding-bottom: calc(48px + 4vw);
  padding-left: ${v(20,140)};
  padding-right: ${v(20,140)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.coolGrey50};
  margin-bottom: calc(32px + 4vw);
`

const TextGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 2vw;
  grid-row-gap: 1vw;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 2vw;
  ${media.md`
    grid-template-columns: auto;
  `}
`

const H1 = styled.h1`
  margin: 0;
  line-height: 1;
  margin-bottom: 2rem;
  ${media.md`
    font-size: ${fontSize.f11};
  `}
  ${media.sm`
    font-size: ${fontSize.f9};
  `}
`

const H2 = styled.h2`
  margin-top: 10px;
  max-width: 80%;
  ${media.md`
    margin-bottom: 18px;
  `}
`

const Date = styled.div`
  color: ${color.coolGrey500};
  margin-bottom: 12px;
  margin-top: -8px;
  ${media.md`
    margin-bottom: 0px;
    margin-top: 8px;
  `}
`

const RoleList = styled.div`
  display: block;
  ${media.md`
    display: none;
  `}
`

const Text = styled.div`
  margin-top: -8px;
  margin-bottom: -8px;
  ${media.md`
    margin-left: 0;
    margin-top: 12px;
  `}
`

const Description = styled.div`
  border-right: 2px solid black;
  ${media.md`
    border-right: none;
    border-bottom: 2px solid black;
    padding-bottom: 32px;
    margin-bottom: 24px;
    margin-top: -20px;
  `}
`

const Article = props => {
  return (
    <GlobalWrapper>
      <Header />
      <Section>
        <Container>
          <TextGrid>
            <div>
              <H1>{props.title}</H1>
              <H2>{props.description}</H2>
            </div>
            <div></div>
            <Description>
              {props.from && (
                <Date>
                  {props.from} &#8212; {props.to}
                </Date>
              )}
              <RoleList>
                {props.roles &&
                  props.roles.map((role, index) => (
                    <div key={'role' + index}>&#8212;&nbsp;&nbsp;{role}</div>
                  ))}
              </RoleList>
            </Description>
            <Text>{props.text}</Text>
          </TextGrid>
        </Container>
        {props.more}
      </Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default Article
