import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { color } from 'styles/theme'

const StyledImgLandscape = styled.div`
  display: block;
  height: ${props =>
    props.aspectRatio ? 43 / props.aspectRatio + 'vw' : '28vw'};
  background: ${color.coolGrey200};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  ${media.md`
  height: ${props =>
    props.aspectRatio ? 88 / props.aspectRatio + 'vw' : '56vw'};
  `}
`

const ImgLandscape = props => {
  return (
    <StyledImgLandscape
      className={props.className}
      img={props.img}
      aspectRatio={props.aspectRatio}
    >
      {props.children}
    </StyledImgLandscape>
  )
}

export default ImgLandscape
