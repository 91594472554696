import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

const StyledTwoImageWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin: 0 6vw 2vw 6vw;
  grid-gap: 2vw;
  ${media.md`
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  `}
`

const TwoImageWrapper = props => {
  return <StyledTwoImageWrapper>{props.children}</StyledTwoImageWrapper>
}

export default TwoImageWrapper
